import React, { useEffect, useRef } from 'react';

import * as workStyles from './index.module.css';

import { setPreset } from '../../three/src/main';
import { gsap } from 'gsap';

import TransitionLink from "gatsby-plugin-transition-link";

import { Helmet } from "react-helmet"

const works =
[
    {
        title: 'Rasterhang',
        path: 'rasterhang'
    },
    {
        title: 'Vectorhang',
        path: 'vectorhang'
    },
]

const Work = ({ transitionStatus }) => {
    const container = useRef(null);
    const worksRef = useRef(null);

    useEffect(() => {
        setPreset(1);
        gsap.to(container.current, { opacity: 1, duration: 1})
        gsap.to(worksRef.current.children, { opacity: 1, duration: 1, stagger: 0.02});
    }, [])

    useEffect(() => {
        if(transitionStatus === 'exiting') {
            gsap.to(worksRef.current.children, { opacity: 0, duration: 1});
        }
    }, [transitionStatus]);

    return (
        <>
            <Helmet>
                <title>Works | Luka Batista</title>
            </Helmet>
            <div className={workStyles.container} ref={container}>
                <div className={workStyles.box}>     
                        <ul className={workStyles.work} ref={worksRef}>
                            {
                                works.map( (w) => { return (
                                                <li key={w.title}>
                                                    <TransitionLink
                                                        to={w.path}
                                                        exit={{
                                                            length: 1,
                                                        }}
                                                        entry={{
                                                        }}
                                                    >
                                                        {w.title}
                                                    </TransitionLink>
                                                </li>
                                            )})
                            }
                        </ul>
                </div>
            </div>
        </>
    );
}

export default Work;